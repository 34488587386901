<template>
  <h-stack class="deviceMsgHead" justify-content="space-between" flex-wrap="wrap">
    <div class="deviceOrder">
      {{ order }}
    </div>
<!--    <div class="deviceType">-->
<!--      {{ deviceType }}-->
<!--    </div>-->
    <div class="deviceMac">
      {{deviceMac}}
    </div>
    <div class="deviceStationMac">
      {{deviceStationMac}}
    </div>
  </h-stack>
</template>

<script>
import HStack from '@/components/hStack/hStack'
export default {
  name: 'deviceMsgHead',
  components: { HStack },
  props: {
    order: {
      type: String,
      default: () => {
        return '#'
      }
    },
    deviceType: {
      type: String,
      default: () => {
        return '-'
      }
    },
    deviceMac: {
      type: String,
      default: () => {
        return '-'
      }
    },
    deviceStationMac: {
      type: String,
      default: () => {
        return '-'
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.deviceMsgHead {
  width: 100%;

  .deviceOrder {
    padding: 5px;
    max-width: calc(25%);
    line-height: 30px;
    word-wrap: break-word;
  }

  .deviceType {
    padding: 5px;
    max-width: 100px;
    line-height: 30px;
    word-wrap: break-word;
    font-size: 20px;
    color: #FF96AD;
  }

  .deviceMac {
    padding: 5px;
    line-height: 30px;
    word-wrap: break-word;
    font-size: 20px;
    color: #FF96AD;
  }

  .deviceStationMac {
    padding: 5px;
    line-height: 30px;
    word-wrap: break-word;
    font-size: 12px;
  }


}


</style>
