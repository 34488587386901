<template>
  <div class="z4CellBGView">
    <device-msg-head :order="order"
                     device-type="Z4"
                     :device-mac="z4Info.deviceMac"
                     :device-station-mac="'基站:' + z4Info.baseStation"
    ></device-msg-head>
    <div style="height: 5px"></div>
    <device-msg-cell v-for="(key) in dataList" :key="key" :content-str="key + ':' + dataMsg(key)">
    </device-msg-cell>
    <device-msg-footer :rssi="dataMsg('rssi')" :time-stamp="dataMsg('dataTime')"></device-msg-footer>
    <div style="width: 95%;height: 1px;background-color: #cccccc;margin: 5px 0px 5px 0px"></div>
  </div>
</template>

<script>
import DeviceMsgHead from '@/views/deviceMQ/deviceMsg/deviceMsgHead'
import DeviceMsgFooter from '@/views/deviceMQ/deviceMsg/deviceMsgFooter'
import DeviceMsgCell from '@/views/deviceMQ/deviceMsg/deviceMsgCell'

export default {
  name: 'z4Cell',
  components: { DeviceMsgCell, DeviceMsgFooter, DeviceMsgHead },
  props: {
    order: {
      type: String,
      default: () => {
        return '#'
      }
    },
    z4Info: {
      type: Object,
      default: () => {
        return {
          'baseStation': '',
          'battery': 0,
          'dataTime': '',
          'deviceMac': '',
          'duration': 0,
          'hwVer': '',
          'id': '',
          'rssi': 0,
          'sendNum': 0,
          'serialNum': 0,
          'sleepTime': 0,
          'softVer': '',
          'status': 0,
          'toSleepTime': 0,
          'type': ''
        }
      }
    }
  },
  computed: {
    dataList() {
      return [
        'baseStation',
        'battery',
        'dataTime',
        'deviceMac',
        'duration',
        'hwVer',
        'rssi',
        'sendNum',
        'serialNum',
        'sleepTime',
        'softVer',
        'status',
        'toSleepTime',
        'type'
      ]
    }
  },
  methods: {
    dataMsg: function(key) {
      if (typeof this.z4Info[key] == 'undefined' || this.z4Info[key] == null) {
        return ''
      }
      return this.z4Info[key]
    }
  }
}
</script>

<style lang="scss" scoped>

.z4CellBGView {
  width: 100%;
}

</style>
