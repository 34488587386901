<template>
  <div class="Z4ViewBGView">
    <ul v-if="viewStyle=='list'" class="infinite-list-wrapper viewList" v-infinite-scroll="updateMsgList" style="overflow:scroll">
      <li v-for="(item, index) in dataList"
          class="infinite-list-item viewLi"
          :infinite-scroll-disabled="disabled"
          @click="clickItem(item)">
        <z4-cell :z4-info="item" :order="'#' + (index + 1)"></z4-cell>
      </li>
    </ul>
    <div v-if="viewStyle=='rssi'" class="rssiView">
      <device-charts  :data-keys="['dataTime', 'rssi']" :data-list="dataList" :data-line-name="'rssi'"></device-charts>
    </div>
  </div>
</template>

<script>

import MQmixin from '@/views/deviceMQ/MQmixin/MQmixin'
import Z4Cell from '@/views/deviceMQ/Z4View/z4Cell'
import DeviceCharts from '@/views/deviceMQ/deviceCharts/deviceCharts'

export default {
  name: 'Z4View',
  components: { DeviceCharts, Z4Cell },
  mixins: [MQmixin],
  data() {
    return {
      viewType:'Z4',
      loadingDivId: 'z4Loading',
      urlBlock: (config) => {
        for (const key in this.query) {
          let value = this.query[key]
          if (typeof config[key] == 'undefined' || key == 'time') {
            config[key] = value
          } else {
            config[key] = config[key] == '' ? value : config[key] + ',' + value
          }
        }
        return this.$api.deviceMQ.getZ4MsgList(config)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.Z4ViewBGView {
  width: 100%;
  height: 100%;

  .viewList {
    width: calc(100%);
    //height: calc(100% - 40px);
    height: calc(100%);
    list-style: none;
    padding: 0;

    .viewLi {
      width: calc(100%);
      margin-bottom: 5px;
    }
  }

  .rssiView {
    width: 100%;
    height: calc(100% - 50px);
    margin-bottom: 10px;
  }

}


</style>
