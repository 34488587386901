<template>
  <div class="deviceChartsView" id="chart"></div>
</template>

<script>

import * as echarts from 'echarts'

export default {
  name: 'deviceCharts',
  props: {
    dataKeys: {
      type: Array,
      default: () => {
        return []
      }
    },
    dataList: {
      type: Array,
      default: () => {
        return []
      }
    },
    dataLineName: {
      type: String,
      default: () => {
        return ''
      }
    },
    chartTitle: {
      type: String,
      default: () => {
        return ''
      }
    }

  },
  data() {
    return {
      chart: null,
      chartList: [],
      timeCountList: [] // 时间间隔
    }
  },
  watch: {
    dataList: {
      handler(value) {
        if (this.dataKeys.length == 0) {
          return
        }
        if (this.chartList.length == 0) {
          this.updateChartList(value)
        } else {
          let newValue = []
          let lastTime = this.chartList[this.chartList.length - 1][0]
          for (let i = 0; i < value.length; i++) {
            let info = value[i]
            let data = []
            for (let j = 0; j < this.dataKeys.length; j++) {
              let key = this.dataKeys[j]
              let value = 0
              if (typeof info[key] != 'undefined' && info[key] != null) {
                value = info[key]
              }
              data.push(value)
              break
            }
            let time = data[0]
            if (lastTime == time) {
              break
            }
            newValue.push(info)
          }
          this.updateChartList(newValue)
        }
      },
      immediate: true,
      deep: true
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.setUpChart()
      window.onresize = () => {
        if (this.chart == null) {
          return
        }
        this.chart.resize();
      }
    })
  },
  methods: {
    updateChartList: function(value) {
      function updateNumberValue(data, valueInfo, fn) {
        let num = data[data.length - 1]
        if (valueInfo.result == false) {
          valueInfo.result = true
          return num
        }
        if (fn == '+') {
          return valueInfo.num >= num ? valueInfo.num : num
        } else {
          return valueInfo.num <= num ? valueInfo.num : num
        }
      }

      let dataInfo1 = {
        minValue: {
          result: false,
          num: 0
        },
        maxValue: {
          result: false,
          num: 1
        }
      }
      let dataInfo2 = {
        minValue: {
          result: false,
          num: 0
        },
        maxValue: {
          result: false,
          num: 1
        }
      }

      let valueConfig = [dataInfo1, dataInfo2]

      for (let i = value.length - 1; i >= 0; i--) {
        let info = value[i]
        let data = []
        for (let j = 0; j < this.dataKeys.length; j++) {
          let key = this.dataKeys[j]
          let value = ''
          if (typeof info[key] != 'undefined' && info[key] != null) {
            value = info[key]
          }
          data.push(value)
        }
        this.chartList.push(data)
        if (data.length == 0) {
          continue
        }
        dataInfo1.minValue.num = updateNumberValue(data, dataInfo1.minValue, '-')
        dataInfo1.maxValue.num = updateNumberValue(data, dataInfo1.maxValue, '+')
        let time = data[0]
        let timeList = []
        if (this.timeCountList.length == 0) {
          timeList = [time, 0]
        } else {
          let last = this.timeCountList[this.timeCountList.length - 1]
          let count = (time - last[0]) / 1000
          timeList = [time, count]
        }
        this.timeCountList.push(timeList)
        dataInfo2.minValue.num = updateNumberValue(timeList, dataInfo2.minValue, '-')
        dataInfo2.maxValue.num = updateNumberValue(timeList, dataInfo2.maxValue, '+')
      }
      this.updateChart(valueConfig)
    },
    updateChart: function(valueConfig) {
      function updateNumberValue(num, valueInfo, fn) {
        if (typeof num == 'undefined' || num == null) {
          return valueInfo.num
        }
        if (fn == '+') {
          return valueInfo.num >= num ? valueInfo.num : num
        } else {
          return valueInfo.num <= num ? valueInfo.num : num
        }
      }

      if (this.chart == null) {
        return
      }

      let option = this.chart.getOption()
      let yAxis = option.yAxis
      let yList = [];
      for (let i = 0; i < yAxis.length; i++) {
        let y = yAxis[i]
        if (i >= valueConfig.length) {
          continue
        }
        let dataInfo = valueConfig[i]
        dataInfo.maxValue.num = dataInfo.maxValue.num * (i == 0 ? 1 : 2.5)
        let max = updateNumberValue(y.max, dataInfo.maxValue, '+')
        let min = updateNumberValue(y.min, dataInfo.minValue, '+')
        let info = {
          min:0,
        }
        if (i == 0) {
          info.max = min * 2 >= max ? min * 2 : max;
        } else {
          info.max = max;
        }
        yList.push(info);
      }

      this.chart.setOption({
        yAxis:yList,
        series: [{
          data: this.chartList
        }, {
          data: this.timeCountList
        }]
      })
    },
    setUpChart: function() {
      let chartDom = document.getElementById('chart')
      this.chart = echarts.init(chartDom)
      let option = {
        tooltip: {
          trigger: 'axis',
          position: function(pt) {
            return [pt[0], '10%']
          }
        },
        title: {
          left: 'center',
          text: this.chartTitle
        },
        xAxis: {
          type: 'time',
          // boundaryGap: false,
          axisLabel: {
            interval: 0,      //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
            rotate: -20    //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
          }
        },
        yAxis: [
          {
            boundaryGap: [0, '100%'],
            type: 'value'
          },
          {
            boundaryGap: [0, '100%'],
            type: 'value'
          }
        ],
        legend: {
          data: [this.dataLineName, '时间间隔']
        },
        dataZoom: [{
          type: 'inside',
          start: 0,
          end: 100,
          filterMode: 'none'
        }, {
          start: 0,
          end: 100,
          filterMode: 'none'
        }],
        series: [
          {
            name: this.dataLineName,
            type: 'line',
            showSymbol: false,
            hoverAnimation: false,
            data: this.chartList,
            color: '#3DB2FF',
            yAxisIndex: 0
          },
          {
            name: '时间间隔',
            type: 'line',
            showSymbol: false,
            hoverAnimation: false,
            data: this.timeCountList,
            color: '#F2BB7B',
            yAxisIndex: 1
          }
        ]
      }
      this.chart.setOption(option)
    }
  }
}
</script>

<style lang="scss" scoped>

.deviceChartsView {
  width: 100%;
  height: 100%;
}

</style>
