<template>
  <h-stack class="deviceMsgFooter" justify-content="space-between">
    <div class="deviceRssi">
      {{ rssi }}
    </div>
    <div class="deviceTimer">
      {{ timeStr }}
    </div>
  </h-stack>
</template>

<script>
import HStack from '@/components/hStack/hStack'
export default {
  name: 'deviceMsgFooter',
  components: { HStack },
  props: {
    rssi: {
      default: 0
    },
    timeStamp: {
      default: 0
    }
  },
  computed: {
    timeStr() {
      if (this.timeStamp <= 0 || this.timeStamp == '') {
        return '-'
      }
      let timeStr = this.formatDate(this.timeStamp)
      let nowTime = new Date().getTime()
      let count = parseInt((nowTime - this.timeStamp) / 1000.0)
      return timeStr + '(' + this.getTimeMsg(count, 1) + '前)'
    }
  }
}
</script>

<style lang="scss" scoped>

.deviceMsgFooter {
  width: 100%;
  margin-top: 10px;

  .deviceRssi {
    width: 100px;
    height: 30px;
    line-height: 30px;
    font-size: 20px;
    color: gray;
    padding-left: 10px;
  }

  .deviceTimer {
    width: calc(100% - 110px);
    line-height: 30px;
    font-size: 16px;
    color: gray;
    padding-right: 10px;
    word-wrap: break-word;
    text-align: right;
  }


}

</style>
