import { Loading } from 'element-ui'

let mixin = {
  data: function() {
    return {
      loadingView: null,
      page: 1,
      pageSize: 30,
      totalCount: 1,
      dataList: [],
      loading: false
    }
  },
  props: {
    query: {
      type: Object,
      default: () => {
        return {}
      }
    },
    viewStyle: {
      default: 'list'
    },
    show: {
      type: Boolean,
      default: false
    },
    searchInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    noMore() {
      return this.dataList.length >= this.totalCount
    },
    disabled() {
      return this.loading || this.noMore
    }
  },
  watch: {
    searchInfo: {
      handler(value) {
        this.reSetPage()
        this.updateMsgList()
      },
      immediate: true,
      deep: true
    },
    loading: {
      handler(value) {
        this.$emit('loadingChange', {result:value})
      },
      immediate: true,
      deep: true
    },
    show: {
      handler(value) {
        this.$nextTick(() => {
          this.reSetPage()
          this.updateMsgList()
        })
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    clickItem: function(item) {
      this.$emit('clickItem', item)
    },
    startLoading: function() {
      let dom = document.getElementById(this.loadingDivId)
      if (typeof dom == 'undefined' || dom == null) {
        return
      }
      this.loadingView = Loading.service({ target: dom })
    },
    reSetPage: function() {
      this.page = 1
      this.dataList = []
      this.totalCount = 1
      this.pageSize = this.viewStyle == 'list' ? 30 : 300
    },
    updateMsgList: function() {
      if (this.show == false || this.noMore || this.loading) {
        if (this.noMore) {
          this.$message({
            message: '没有更多了'
          });
        }
        return
      }
      if (this.page == 2) {
        this.$emit('stopRefresh', {})
      }
      this.loading = true
      this.getMsgData().then(res => {
        this.loading = false
        this.page = this.page + 1
        this.dataList = this.dataList.concat(res.dataList)
        this.totalCount = res.totalCount
        this.$emit('updateNumber', { totalCount: this.totalCount, type: this.viewType })
      })
    },
    getMsgData() {
      return new Promise(resolve => {
        if (typeof this.urlBlock == 'undefined' || this.urlBlock == null) {
          resolve({ totalCount: 0, dataList: [] })
          return
        }

        let config = {}
        for (const key in this.searchInfo) {
          config[key] = this.searchInfo[key]
        }

        config['page'] = this.page
        config['pageSize'] = this.pageSize



        this.urlBlock(config).then(res => {
          let totalCount = 0
          if (typeof res['totalCount'] != 'undefined' && res['totalCount'] != null) {
            totalCount = res['totalCount']
          }
          let dataList = []
          if (typeof res['data'] != 'undefined' && res['data'] != null) {
            dataList = res['data']
          }
          resolve({ totalCount: totalCount, dataList: dataList })
        }).catch((res) => {
          resolve({ totalCount: 0, dataList: [] })
        })
      })
    }
  }
}

export default mixin
