<template>
<div class="deviceMsgCell">
  {{contentStr}}
</div>
</template>

<script>
export default {
  name: 'deviceMsgCell',
  props:{
    contentStr:{
      type:String,
      default:()=>{
        return '-'
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.deviceMsgCell {
  width: 95%;
  transform: translate(2.6315%, 0px);
  line-height: 20px;
  font-size: 15px;
  word-wrap: break-word;
  margin-top: 5px;
}

</style>
